<template>
  <div>
    <img
      class="lazyload h-full w-full bg-plann-gray-input object-cover"
      :class="{ 'rounded-lg': rounded }"
      :data-src="postMedia.big_preview_url"
      :data-srcset="srcSet"
      data-sizes="auto"
    >
  </div>
</template>

<script setup lang="ts">

const props = defineProps<{
  postMedia: MediaElement,
  alt: string | undefined,
  rounded?: boolean,
  imageCrops?: imageCrops[],
}>()

const srcSet = computed(() => {
  if (!props.imageCrops) { return `${props.postMedia.thumb_url_uncropped} 500w, ${props.postMedia.preview_url} 780w, ${props.postMedia.big_preview_url} 1170w` }

  const crops = []

  if (props.imageCrops.includes(500)) {
    crops.push(`${props.postMedia.thumb_url_uncropped} 500w`)
  }

  if (props.imageCrops.includes(780)) {
    crops.push(`${props.postMedia.preview_url} 780w`)
  }

  if (props.imageCrops.includes(1170)) {
    crops.push(`${props.postMedia.big_preview_url} 1170w`)
  }

  return crops.join(', ')
})
</script>
